<template>
  <div class="field p-fluid">
    <Divider />
    <label for="completion_time">Completion Time</label>
    <p class="subtitle">
      The time these tarping works were completed.
    </p>
    <DatetimePicker inputId="completion_time" v-model="form.completion_time" />
    <div v-if="form.completion_time && store.selectedStackLastTarpingWorks">
      <Message v-if="new Date(form.completion_time) <= new Date(store.selectedStackLastTarpingWorks.completion_time)" severity="warn" :closable="false">
        The selected date and time is before the last recorded tarping works for this stack 
        ({{ new Date(store.selectedStackLastTarpingWorks.completion_time).toLocaleString('en-AU', {day: 'numeric', month: 'short'}) }}, {{ new Date(store.selectedStackLastTarpingWorks.completion_time).toLocaleString('en-AU', {hour: 'numeric', minute: 'numeric'}) }}).
      </Message>
    </div>
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import DatetimePicker from '@/components/forms/DatetimePicker.vue';
import Divider from 'primevue/divider';
import Message from 'primevue/message';

export default {
  name: "CompletionTime",
  components: {
    DatetimePicker,
    Divider,
    Message
  },
  created() {
      this.store = useRecordTarpingWorksStore();
  },
  computed: {
    form() {
        return this.store.form;
    },
  }
}

</script>