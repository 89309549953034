<template>
  <Dialog 
    :visible="modelValue"
    modal 
    :header="header"
    :style="modalStyles"
    :blockScroll="blockScroll"
    :dismissableMask="dismissableMask"
    @update:visible="closeModal"
  >
    <slot></slot>
  </Dialog>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';

import Dialog from 'primevue/dialog';

const props = defineProps({
  modelValue: Boolean,
  styles: {
    type: Object,
    default: () => ({}),
  },
  header: String,
  blockScroll: {
    type: Boolean,
    default: true,
  },
  dismissableMask: {
    type: Boolean,
    default: true,
  },
});

const defaultStyles = {
  width: '100%',
  maxHeight: '100vh',
  borderRadius: '0',
  background: 'white',
};
const modalStyles = computed(() => ({
  ...defaultStyles,
  ...props.styles,
}));

const emit = defineEmits(['update:modelValue']);

const closeModal = () => {
  emit('update:modelValue', false);
};

</script>
