<template>
  <div class="field">
    <label for="activities">Activity</label>
    <p class="subtitle">
      Select one or multiple
    </p>
    <div>
      <RadioSelect
        v-for="activity in activities"
        :key="activity.value"
        class="mb-2"
        :modelValue="modelValue"
        :value="activity.value"
        :label="activity.label"
        :multiple="true"
        @update:modelValue="update"
      />
    </div>
  </div>
</template>

<script>
import Divider from 'primevue/divider';
import RadioSelect from '@/components/forms/RadioSelect.vue';

export default {
  name: "ActivitySelect",
  props: ['modelValue'],
  components: {
    Divider,
    RadioSelect,
  },
  data() { return {
    activities: [
      { value: 'tarp_on_pull_out', label: 'Putting tarp on stack and pulling out' },
      { value: 'pulling_tarps_out', label: 'Pulling tarps (Out/Down/Up the Face)' },
      { value: 'perming', label: 'Perming' },
      { value: 'wozzying', label: 'Wozzying'},
      { value: 'small_end_wall', label: 'Small End Wall' },
      { value: 'itp', label: 'ITP'},
      { value: 'cbh_supervisor_input', label: 'CBH Site Supervisor Input'},
    ],
  }},
  methods: {
    update(activities) {
      this.$emit('update:modelValue', activities)
    }
  }
}

</script>