<template>
  <div>
    <ul class="tarpingWork-attr-list">
      <li v-for="field in fields" :key="field.label">
        <span class="attr">
          {{ field.label }}
        </span>
        <span class="value">
          {{ field.value }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TarpingWorkDetails',
  inject: ['dialogRef'],
  created() {
    this.tarpingWorks = this.dialogRef.data.tarpingWorks
  },
  data () { return {
    tarpingWorks: null,
  }},
  computed: {
    fields() {
      return this.tarpingWorks.summaryItems();
    }
  }
}
</script>

<style scoped>
.tarpingWork-attr-list {
  list-style: none;
  padding: 0;
  margin-left: -6px;
  margin-right: -6px;
}
.tarpingWork-attr-list li {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}
.tarpingWork-attr-list li:nth-child(odd) {
  background-color: #f2f2f2;
}
.tarpingWork-attr-list li .attr {
  font-weight: bold;
  width: 155px;
  flex-shrink: 0;
}
.tarpingWork-attr-list li .value {
  flex-grow: 1;
  text-align: left;
}
</style>
