<template>
  <div v-if="form.is_new_stack === true && isIASTenant">
    <div class="field p-fluid">
      <Divider />
      <label for="has_carryover_grain">
        Which end is the OBH filling FROM (i.e. what colour end is the grain moving away from). 
        For Stacks that start some way along an OBH (i.e. from a Dwarf Wall or a 
        Carryover Stack), please still input the End the grain is moving away 
        FROM so the direction of grain movement can be determined.
      </label>
      <RadioSelect 
        v-model="form.filling_end" 
        value="Blue"
        label="Blue"
        name="filling_end"
        class="mb-2"
      />
      <RadioSelect
        v-model="form.filling_end" 
        value="Red"
        label="Red"
        name="filling_end"
        class="mb-2"
      />
    </div>
  </div>
</template>

<script>
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';

import Divider from 'primevue/divider';
import RadioSelect from '@/components/forms/RadioSelect.vue';

import { isIASTenant } from '@/utils/ui';

export default {
  name: "FillingEnd",
  components: {
    Divider,
    RadioSelect,
  },
  computed: {
    isIASTenant() {
      // Question only required for IAS tarping
      return isIASTenant();
    },
    form() {
      return this.store.form;
    },
  },
  created() {
      this.store = useRecordTarpingWorksStore();
  },
  
}
</script>