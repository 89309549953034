import { v4 as uuidv4 } from 'uuid';

import * as Sentry from "@sentry/vue";

import { useTarpInventoryStore } from '@/stores/TarpInventory';
import { useStacksStore } from '@/stores/Stacks';
import { useSitesStore } from '@/stores/Sites';
import { useStoragesStore } from '@/stores/Storages';
import { useRecordTarpingWorksStore } from '@/stores/RecordTarpingWorks';
import Supervisor from './Supervisor';
import TarpingWorksAPI from "@/stores/api/TarpingWorks";


const ACTIVITIES = [
  { value: 'tarp_on_pull_out', display: 'Putting tarp on stack and pulling out' },
  { value: 'pulling_tarps_out', display: 'Pulling tarps (Out/Down/Up the Face)' },
  { value: 'perming', display: 'Perming' },
  { value: 'wozzying', display: 'Wozzying' },
  { value: 'small_end_wall', display: 'Small end wall' },
  { value: 'itp', display: 'ITP' },
  { value: 'cbh_supervisor_input', display: 'CBH Supervisor Input' },
]


class TarpingWorks {

  id = null;
  client_id = null;
  supervisor = null;
  is_new_stack = null;
  filling_end = null;
  has_carryover_grain = null;
  starting_peak_actual = null;
  pulled_back_folded_tarp_length = null;
  site = null;
  storage = null;
  stack = null;
  new_stack = null; // Stores details about new stack if created
  new_stack_commodity = null; // API only requires this field for creating stack in database
  completion_time = null;
  peak_actual = null;
  toe_location = null;
  activities = [];
  tarps = [];
  new_tarps = [];
  wind_speed = null;
  stack_closed_out = null;
  cover_actual = null;
  cover_actual_max = false;
  cover_estimated = null;
  cover_estimated_max = false;
  permed = null;
  permed_max = false;
  seams_wozzied = null;
  seams_wozzied_max = false;
  comments = null;

  tarpInventoryStore = null;
  stacksStore = null;

  constructor() {
    this.client_id = uuidv4();

    this.tarpInventoryStore = useTarpInventoryStore();
    this.stacksStore = useStacksStore();
    this.storagesStore = useStoragesStore();
    this.sitesStore = useSitesStore();
  }

  // Adapters

  toJSON() {
    const tarps = this.tarps.filter(tarp => tarp !== null);

    let siteId, storageId, stackId;
    if (this.site !== null) siteId = this.site.id;
    if (this.storage !== null) storageId = this.storage.id;
    
    if (this.stack === 'new_stack') stackId = 'new_stack';
    else if (this.stack)   stackId = this.stack.id;

    return {
      id: this.id,
      client_id: this.client_id,
      supervisor: (this.supervisor) ? this.supervisor.toJSON() : null,
      is_new_stack: this.is_new_stack,
      filling_end: this.filling_end,
      has_carryover_grain: this.has_carryover_grain,
      starting_peak_actual: this.starting_peak_actual,
      pulled_back_folded_tarp_length: this.pulled_back_folded_tarp_length,

      site: siteId,
      storage: storageId,
      stack: stackId,
      
      new_stack: this.new_stack,
      new_stack_commodity: this.new_stack_commodity,
      completion_time: this.completion_time,
      peak_actual: this.peak_actual,
      toe_location: this.toe_location,
      activities: [...this.activities],
      tarps: tarps.map(tarp => tarp.id),
      new_tarps: [...this.new_tarps],
      wind_speed: this.wind_speed,
      stack_closed_out: this.stack_closed_out,
      cover_actual: this.cover_actual,
      cover_actual_max: this.cover_actual_max,
      cover_estimated: this.cover_estimated,
      cover_estimated_max: this.cover_estimated_max,
      permed: this.permed,
      permed_max: this.permed_max,
      seams_wozzied: this.seams_wozzied,
      seams_wozzied_max: this.seams_wozzied_max,
      comments: this.comments,
    }
  }

  fromJSON(json) {
    this.id = json.id;
    this.client_id = json.client_id;
    this.supervisor = new Supervisor().fromJSON(json.supervisor);
    this.is_new_stack = json.is_new_stack;
    this.filling_end = json.filling_end;
    this.has_carryover_grain = json.has_carryover_grain;
    this.starting_peak_actual = json.starting_peak_actual;
    this.pulled_back_folded_tarp_length = json.pulled_back_folded_tarp_length;

    if (json.site)
      this.site = this.sitesStore.getSiteById(json.site);
      
    if (json.storage)
      this.storage = this.storagesStore.getStorageById(json.storage);

    if (json.stack && json.stack !== 'new_stack') {
      this.stack = this.stacksStore.getStackById(json.stack);
    } else {
      this.stack = json.stack;
    }

    this.new_stack = json.new_stack;
    this.new_stack_commodity = json.new_stack_commodity;
    this.completion_time = json.completion_time;
    this.peak_actual = json.peak_actual;
    this.toe_location = json.toe_location;
    this.activities = json.activities;
    
    // TODO: Fix this
    const tarps = json.tarps.filter(tarp => tarp !== null);
    this.tarps = tarps.map(tarp => {
      // Tarp can be either a string or an object
      let tarpId;
      (typeof tarp === 'string') ? tarpId = tarp : tarpId = tarp.id;
      return this.tarpInventoryStore.getTarpById(tarpId)
    });

    this.tarps = this.tarps.filter(tarp => tarp !== null);
    this.new_tarps = json.new_tarps;
    this.wind_speed = json.wind_speed;
    this.stack_closed_out = json.stack_closed_out;
    this.cover_actual = json.cover_actual;
    this.cover_actual_max = json.cover_actual_max;
    this.cover_estimated = json.cover_estimated;
    this.cover_estimated_max = json.cover_estimated_max;
    this.permed = json.permed;
    this.permed_max = json.permed_max;
    this.seams_wozzied = json.seams_wozzied;
    this.seams_wozzied_max = json.seams_wozzied_max;
    this.comments = json.comments;

    return this;
  }

  stackRef() {
    const stack = this.stack
    if (!stack) return 'Unknown Stack';
    return `${stack.storage.site.display_name}/${stack.storage.display_name}/${stack.name.toUpperCase()}`;
  }

  completionTimeDisplay() {
    const completionTime = this.completion_time;
    if (!completionTime) return 'Unknown Completion Time';
    return new Date(completionTime).toLocaleString();
  }

  activitiesDisplay() {
    console.log(this.activities);
    const activities = this.activities.map(activity => {
      const activityObj = ACTIVITIES.find(a => a.value === activity);
      return activityObj.display;
    });
    return activities.join(', ');
  }

  supervisorDisplay() {
    const supervisor = this.supervisor;
    if (!supervisor) return 'Unknown Supervisor';

    const firstName = supervisor.first_name;
    const lastName = supervisor.last_name;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else if (firstName) return firstName;
    else if (lastName) return lastName;
    else return supervisor.email;
  }

  coverEstimatedDisplay() {
    if (this.cover_estimated_max) return 'Max';
    return this.cover_estimated;
  }

  coverActualDisplay() {
    if (this.cover_actual_max) return 'Max';
    return this.cover_actual;
  }

  permedDisplay() {
    if (this.permed_max) return 'Max';
    return this.permed;
  }

  seamsWozziedDisplay() {
    if (this.seams_wozzied_max) return 'Max';
    return this.seams_wozzied;
  }

  tarpsDisplay() {
    if (!this.tarps) return '';
    return this.tarps.map(tarp => tarp.serial_no).join(', ');
  }

  summaryItems(filterFields, excludeFields) {
    // Returns an array of objects with label and value fields for
    // use in a KeyValueList component.
    let fields = [
      { label: 'Stack', value: this.stackRef() },
      { label: 'Completion Time', value: this.completionTimeDisplay() },
      { label: 'Supervisor', value: this.supervisorDisplay() },
    ]

    if (this.is_new_stack === true) {
      fields.push({ label: 'Is New Stack', value: 'Yes' });
      fields.push({ label: 'Has Carryover Grain', value: (this.has_carryover_grain) ? 'Yes' : 'No' });
      
      if (this.has_carryover_grain === true) {
        fields.push({ label: 'Starting PA', value: this.starting_peak_actual });
        fields.push({ label: 'Pulled Back Folded Tarp Length', value: this.pulled_back_folded_tarp_length });
      }
      if (this.filling_end) fields.push({ label: 'Filling End', value: this.filling_end });
    }

    fields.push({ label: 'Activities', value: this.activitiesDisplay() });

    if (this.cover_estimated || this.cover_estimated_max === true) fields.push({ label: 'Cover Estimated', value: (this.cover_estimated_max) ? 'Max' : this.cover_estimated });
    if (this.peak_actual) fields.push({ label: 'Peak Actual', value: this.peak_actual });
    if (this.toe_location) fields.push({ label: 'Toe Location', value: this.toe_location });
    if (this.cover_actual || this.cover_actual_max === true) fields.push({ label: 'Cover Actual', value: (this.cover_actual_max) ? 'Max' : this.cover_actual });
    if (this.wind_speed) fields.push({ label: 'Wind Speed', value: this.wind_speed });
    if (this.tarps.length > 0) fields.push({ label: 'Tarps', value: this.tarpsDisplay() });
    if (this.permed || this.permed_max) fields.push({ label: 'Permed', value: (this.permed_max) ? 'Max' : this.permed });
    if (this.seams_wozzied || this.seams_wozzied_max) fields.push({ label: 'Seams Wozzied', value: (this.seams_wozzied_max) ? 'Max' : this.seams_wozzied });
    if (this.comments) fields.push({ label: 'Comments', value: this.comments });
    if (this.stack_closed_out === true)  fields.push({ label: 'Stack Closed Out', value: 'Yes' });

    if (filterFields) fields = fields.filter(field => filterFields.includes(field.label));
    if (excludeFields) fields = fields.filter(field => !excludeFields.includes(field.label));

    return fields
  }

  // Actions

  updateTarpingWorksInStores(response) {
    // Removes the tarping works from the locally saved tarping works and 
    // adds the updated tarping works to the tarping works store.
    const recordTarpingWorksStore = useRecordTarpingWorksStore();
    recordTarpingWorksStore.removeTarpingWorksFromLocal(response.data.client_id);
    recordTarpingWorksStore.addToTarpingWorksStore(response.data);
  }

  syncToServer = async (data) => {
    if (!data) data = this.toJSON();

    const api = new TarpingWorksAPI();

    let response;

    try {

      if (this.id) response = await api.updateTarpingWorks(this.id, data);
      else         response = await api.createTarpingWorks(data);

      if ([200, 201].includes(response.status)) {
        this.updateTarpingWorksInStores(response);
      }

      return response;

    } catch (error) {

      Sentry.captureException(error);

      if (error.response) {
        if (error.response.status === 409) {
          // 409 is returned if the tarping works has already been created on the server
          this.updateTarpingWorksInStores(error.response);
        }
        return error.response;
      } else {
        throw error;
      }
      
    }
  }

}

export default TarpingWorks
